<template>
  <nav class="main-header navbar navbar-expand"
       :class="{'navbar-dark' : isDarkMode, 'navbar-white navbar-light' : !isDarkMode}">

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto d-none d-lg-block">
      <li class="nav-item d-none d-lg-inline-block nav-link">
        <p class="custom-control custom-switch">
          <i class="fas fa-sun mr-5"></i>
          <input type="checkbox" class="custom-control-input" id="customSwitch1" @change="changeMode()"
                 v-model="isDarkMode">
          <label class="custom-control-label" for="customSwitch1"></label>
          <i class="fas fa-moon"></i>
        </p>
      </li>
      <li @click=logout() class="nav-item d-none d-lg-inline-block nav-link text-orange" style="cursor: pointer">
        <p><i class="fas fa-sign-out-alt"></i></p>
      </li>
    </ul>

    <!-- mobile -->
    <div class="container-fluid d-lg-none">
      <span class="navbar-brand mb-0 h1 pl-2" @click="goMain()">드레스룸 도면</span>
      <button type="button" class="btn" @click="showMobileMenu()">
        <span><i class="fas fa-bars"></i></span>
      </button>
    </div>
  </nav>

  <!-- mobile-menu modal -->
  <div class="modal fade modal-m-menu" id="mobileMenuModal" tabindex="-1" aria-labelledby="mobileMenuModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-nav-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-nav-body">
          <ul v-if="role !== null">
            <li v-if="role.includes('master')">
              <a @click="goPage('AdminAccount')">관리자 계정 관리</a>
              <ul>
                <li>
                  <a @click="goPage('AdminAccount')">계정 등록/수정</a>
                </li>
              </ul>
            </li>
            <li v-if="role.includes('admin')">
              <a @click="goPage('PlanManage')">도면 관리</a>
              <ul>
                <li>
                  <a @click="goPage('PlanManage')">도면 등록/수정</a>
                </li>
              </ul>
            </li>
            <li v-if="role.includes('master') || role.includes('admin')">
              <a @click="goPage('UserAccount')">사용자 계정 관리</a>
              <ul>
                <li>
                  <a @click="goPage('UserAccount')">계정 등록/수정</a>
                </li>
              </ul>
            </li>
            <li v-if="role.includes('master')">
              <a @click="goPage('PlanManage')">도면 관리</a>
              <ul>
                <li>
                  <a @click="goPage('PlanManage')">도면 등록/수정</a>
                </li>
              </ul>
            </li>
            <li v-if="role.includes('user')">
              <a @click="goPage('PlanList')">도면 목록</a>
              <ul>
                <li>
                  <a @click="goPage('PlanList')">도면 검색</a>
                </li>
              </ul>
            </li>
            <li>
              <a @click="goPage('Login')">로그아웃</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      data: [],
      isDarkMode: false,
      role: sessionStorage.getItem('role')
    }
  },
  components: {},
  created() {
    if (localStorage.getItem('isDarkMode') === '1') {
      this.isDarkMode = true
    } else {
      this.isDarkMode = false
    }
  },
  mounted() {
  },
  methods: {
    goMain() {
      let role = sessionStorage.getItem('role') // 최고관리자(master), 관리자(admin), 일반유저(user)

      let name = ''
      if (role.includes('master')) {
        name = 'AdminAccount'
      } else if (role.includes('admin')) {
        name = 'PlanManage'
      } else if (role.includes('user')) {
        name = 'PlanList'
      }

      this.goPage(name)
    },
    changeMode() {
      if (localStorage.getItem('isDarkMode') === '1') {
        localStorage.setItem('isDarkMode', '0')
        this.isDarkMode = false
        this.emitter.emit(this.$EVENT_MODE_CHANGE)
      } else {
        localStorage.setItem('isDarkMode', '1')
        this.isDarkMode = true
        this.emitter.emit(this.$EVENT_MODE_CHANGE)
      }
    },
    logout() {
      this.$router.push({name: 'Login'})
    },
    showMobileMenu() {
      window.$('#mobileMenuModal').modal('show');
    },
    goPage(_name) {
      if (_name === this.$route.name) {
        this.$router.go(0)
      } else {
        this.$router.push({name: _name})
      }

      window.$('#mobileMenuModal').modal('hide');
    },
  }
}
</script>

<style>
</style>
