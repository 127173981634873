<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">관리자 계정 관리</h4>
            <span>관리자 계정을 관리합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="row">
              <div class="ml-auto">
                <button class="btn btn-success" @click="goRegister()">계정 등록</button>
              </div>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">계정 목록</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>No</th>
                    <th>이메일</th>
                    <th>이름</th>
                    <!--                    <th>메모</th>-->
                    <th>사용</th>
                    <th>수정</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ totalCount - ((page - 1) * size) - index }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.name }}</td>
                    <!--                    <td>{{ item.memo }}</td>-->
                    <td class="position-relative">
                      <input class="position-absolute" type="checkbox" :checked="item.deleted === 0">
                      <div class="btn-check" @click="useAdmin(index)"></div>
                    </td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="goEdit(item)">수정</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
    <div class="modal fade" id="adminAddModal" tabindex="-1" aria-labelledby="adminAddModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="adminAddModalLabel">계정 등록</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!--            <p class="text-danger">* 메모 외 항목은 필수입니다.</p>-->
            <div class="input-group pb-1">
              <input type="text" class="form-control" v-model.trim="addEmail" placeholder="*이메일">
            </div>
            <div class="input-group pb-1">
              <input type="password" class="form-control" v-model.trim="addPw"
                     placeholder="*비밀번호(4자 이상)" maxlength="30">
            </div>
            <div class="input-group pb-1">
              <input type="text" class="form-control" v-model.trim="addName" placeholder="*이름" maxlength="25">
            </div>
            <!--            <div class="input-group pb-1">-->
            <!--              <input type="text" class="form-control" v-model.trim="addMemo" placeholder="메모">-->
            <!--            </div>-->
          </div>
          <div class="modal-footer">
            <input type="button" class="btn btn-info" value="등록" @click="register()"/>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">취소</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="adminEditModal" tabindex="-1" aria-labelledby="adminEditModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="adminEditModalLabel">계정정보 수정 ({{ editEmail }})</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-danger">* 수정하실 항목을 입력해주세요</p>
            <div class="input-group pb-1">
              <input type="password" class="form-control" v-model.trim="editPw"
                     placeholder="비밀번호(4자 이상)" maxlength="30">
            </div>
            <div class="input-group pb-1">
              <input type="text" class="form-control" v-model.trim="editName" placeholder="이름" maxlength="25">
            </div>
            <!--            <div class="input-group pb-1">-->
            <!--              <input type="text" class=" form-control" v-model.trim="editMemo" placeholder="메모">-->
            <!--            </div>-->
          </div>
          <div class="modal-footer">
            <input type="button" class="btn btn-info" value="저장" @click="edit()"/>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">취소</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/common/Pagination";

export default {
  name: "AdminAccount",
  path: "/admin/account",
  components: {
    Pagination
  },
  data() {
    return {
      items: [],
      size: 10,
      page: 1,
      totalCount: 0,

      addEmail: '',
      addPw: '',
      addName: '',
      addMemo: '',

      /*수정*/
      id: 0,
      editEmail: '',
      editPw: '',
      editName: '',
      editMemo: '',
    }
  },
  created() {
    this.emitter.emit(this.$EVENT_LNB_CHANGE, 'admin', 'account')
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page
    }
    if (this.$route.query.size) {
      this.size = this.$route.query.size
    }

    this.getList(this.page)
  },
  methods: {
    getList(_page) {
      this.$get(this.$SETTING_ADMIN + '?page=' + _page + '&perPage=' + this.size, this.$options.name + '_getList', (result) => {
        this.items = result.data.data
        this.totalCount = result.data.total

        this.page = _page

        /* 페이지네이션 */
        try {
          this.$refs.Pagination.init(this.$options.name, null, {}, this.page, this.totalCount, Number(this.size))

        } catch (e) {
          console.log(e)
        }

      }, (result) => {
        this.httpError(result)
      })
    },
    goRegister() {
      this.addEmail = ''
      this.addPw = ''
      this.addName = ''
      // this.addMemo = ''

      window.$('#adminAddModal').modal('show');
    },
    register() {
      if (this.addEmail === '') {
        this.notify('error', '이메일을 입력해주세요.')
        return
      }
      if (!this.validateEmail(this.addEmail)) {
        this.notify('error', '이메일을 정확하게 입력해주세요.')
        return
      }
      if (this.addPw === '') {
        this.notify('error', '비밀번호를 입력해주세요.')
        return
      }
      if (this.addPw.length < 4){
        this.notify('error', '비밀번호를 4자 이상 입력해주세요.')
        return
      }
      if (this.addName === '') {
        this.notify('error', '이름을 입력해주세요.')
        return
      }
      if (this.addName.length < 2) {
        this.notify('error', '이름은 2자 이상이어야 합니다.')
        return
      }

      let queryString = '?email=' + this.addEmail + '&password=' + encodeURIComponent(this.addPw) + '&name=' + this.addName
      // if (this.addMemo !== '') {
      //   queryString = queryString + '&memo=' + this.addMemo
      // }

      this.$post(this.$SETTING_ADMIN_REGISTER + queryString, this.$options.name + '_register', null, () => {
        this.notify('success', '성공적으로 등록되었습니다.')
        window.$('#adminAddModal').modal('hide');
        this.getList(1)

      }, (result) => {
        this.httpError(result)
      })
    },
    goEdit(_item) {
      this.id = _item.id
      this.editEmail = _item.email
      this.editPw = ''
      this.editName = ''
      // this.editMemo = ''

      window.$('#adminEditModal').modal('show');
    },
    edit() {
      // if (this.editName === '' && this.editPw === '' && this.editMemo === '') {
      //   this.notify('error', '수정하실 항목을 입력해주세요')
      //   return
      // }

      if (this.editName === '' && this.editPw === '') {
        this.notify('error', '수정하실 항목을 입력해주세요')
        return
      }


      let queryString = ''

      if (this.editName !== '') {
        if (this.editName.length >= 2) {
          queryString = queryString + '&name=' + this.editName
        } else {
          this.notify('error', '이름은 2자 이상이어야 합니다.')
          return
        }
      }
      if (this.editPw !== '') {
        if (this.editPw.length >= 4) { // 비밀번호 정규식 체크
          queryString = queryString + '&password=' + encodeURIComponent(this.editPw)
        } else {
          this.notify('error', '비밀번호를 4자 이상 입력해주세요.')
          return
        }
      }
      // if (this.editMemo !== '') {
      //   queryString = queryString + '&memo=' + this.editMemo
      // }

      queryString = queryString.replace('&', '?')

      this.$put(this.$SETTING_ADMIN_EDIT + this.id + queryString, this.$options.name + '_edit', null, () => {
        this.notify('success', '성공적으로 변경되었습니다.')
        window.$('#adminEditModal').modal('hide');
        this.getList(this.page)

      }, (result) => {
        this.httpError(result)
      })
    },
    useAdmin(_idx) {
      /* 0:사용, 1:미사용 */
      let item = this.items[_idx]
      let deleted = item.deleted
      if (deleted === 0) {
        deleted = 1
      } else {
        deleted = 0
      }

      this.$delete(this.$SETTING_ADMIN_DELETE + item.id + '?deleted=' + deleted, this.$options.name + '_useAdmin', () => {
        this.notify('success', '성공적으로 변경되었습니다.')
        this.items[_idx].deleted = deleted

      }, (result) => {
        this.httpError(result)
      })
    }
  },
  watch: {
    '$route.query.page'() {
      if (this.$route.query.page) {
        this.getList(this.$route.query.page)
      } else {
        this.getList(1)
      }
    },
  }
}
</script>

<style scoped>

</style>