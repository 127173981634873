<template>
  <aside class="main-sidebar elevation-4 d-none"
         :class="{'sidebar-dark-primary' : isDarkMode, 'sidebar-light-white' : !isDarkMode, 'd-lg-block' : !$route.meta.hideLNBHeader}">
    <!-- Brand Logo -->
    <div class="brand-link" @click="goMain()">
      <!--      <img src="dist/img/AdminLTELogo.png" alt="ADMIN Logo" class="brand-image img-circle elevation-3" style="opacity: .8">-->
      <span class="brand-text font-weight-bold pl-2">드레스룸 도면</span>
      <!--      <span class="brand-text font-weight-light">ADMIN</span>-->
    </div>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2 pt-3">
        <!-- 아이콘 생성 -->
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
            data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li v-for="(navItem, index) in lnb" :key="index" class="nav-item"
              v-bind:class="{ 'menu-open' : selectedCode === navItem.code }">
            <a class="nav-link" v-bind:class="{ active : selectedCode === navItem.code}">
              <i class="nav-icon" v-bind:class="navItem.icon"></i>
              <p>
                {{ navItem.title }}
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview" v-show="selectedCode === navItem.code">
              <li v-for="(subItem, subIndex) in navItem.sub" :key="subIndex" class="nav-item">
                <div class="nav-link" :class="{ active : selectedSubCode === subItem.subCode }" @click="goPage(subItem.link)">
                  <p>{{ subItem.subTitle }}</p>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
export default {
  data() {
    return {
      isDarkMode: false,
      selectedCode: null,
      selectedSubCode: null,
      lnb: [],
    }
  },
  created() {
    this.emitter.on(this.$EVENT_LNB_CHANGE, (_code, _subCode) => {
      this.selectedCode = _code
      this.selectedSubCode = _subCode
    })

    if (localStorage.getItem('isDarkMode') === '1') {
      this.isDarkMode = true
    } else {
      this.isDarkMode = false
    }

    this.emitter.on(this.$EVENT_MODE_CHANGE, () => {
      if (localStorage.getItem('isDarkMode') === '1') {
        this.isDarkMode = true
      } else {
        this.isDarkMode = false
      }
    })

    this.setLNB()

    this.emitter.on(this.$EVENT_SET_LNB, () => {
      this.setLNB()
    })
  },
  methods: {
    goMain() {
      let role = sessionStorage.getItem('role') // 최고관리자(master), 관리자(admin), 일반유저(user)

      let name = ''
      if (role.includes('master')) {
        name = 'AdminAccount'
      } else if (role.includes('admin')) {
        name = 'PlanManage'
      } else if (role.includes('user')) {
        name = 'PlanList'
      }

      if (name === this.$route.name) {
        this.$router.go(0)
      } else {
        this.$router.push({name: name})
      }
    },
    goPage(_name) {
      if (_name === this.$route.name) {
        this.$router.go(0)
      } else {
        this.$router.push({name: _name})
      }
    },
    setLNB() {
      let role = sessionStorage.getItem('role') // 최고관리자(master), 관리자(admin), 일반유저(user)

      if (role !== null) {
        if (role.includes('master')) {
          this.lnb = [
            {
              'icon': 'fas fa-user-cog',
              'title': '관리자 계정 관리',
              'code': 'admin',
              'sub': [
                {
                  'subTitle': '계정 등록/수정',
                  'subCode': 'account',
                  'link': 'AdminAccount',
                },
              ]
            },
            {
              'icon': 'fas fa-user',
              'title': '사용자 계정 관리',
              'code': 'user',
              'sub': [
                {
                  'subTitle': '계정 등록/수정',
                  'subCode': 'account',
                  'link': 'UserAccount',
                },
              ]
            },
            {
              'icon': 'fas fa-file',
              'title': '도면 관리',
              'code': 'admin_plan',
              'sub': [
                {
                  'subTitle': '도면 등록/수정',
                  'subCode': 'manage',
                  'link': 'PlanManage',
                },
              ]
            },
          ]

        } else if (role.includes('admin')) {
          this.lnb = [
            {
              'icon': 'fas fa-file',
              'title': '도면 관리',
              'code': 'admin_plan',
              'sub': [
                {
                  'subTitle': '도면 등록/수정',
                  'subCode': 'manage',
                  'link': 'PlanManage',
                },
              ]
            },
            {
              'icon': 'fas fa-user',
              'title': '사용자 계정 관리',
              'code': 'user',
              'sub': [
                {
                  'subTitle': '계정 등록/수정',
                  'subCode': 'account',
                  'link': 'UserAccount',
                },
              ]
            }
          ]

        } else if (role.includes('user')) {
          this.lnb = [
            {
              'icon': 'fas fa-file',
              'title': '도면 목록',
              'code': 'user_plan',
              'sub': [
                {
                  'subTitle': '도면 검색',
                  'subCode': 'list',
                  'link': 'PlanList',
                },
              ]
            },
          ]
        }
      }
    },
  }
}
</script>

<style>
</style>
